<template>
  <div class="index">
    <!-- <div class="index_main">
      <div class="main_top">
        <el-upload
          action="#"
          :limit="num"
          list-type="picture-card"
          ref="upload"
          :auto-upload="false"
          :on-change="handleDownload"
        >
          <i class="el-icon-upload">请上传单位执照</i>

          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
      <div style="margin: 0 auto; width: 80%"></div>
      <div class="main_form">
        <h5>申报奖项</h5>
        <el-form
          :inline="true"
          :model="labelPosition"
          class="demo-form-inline"
          label-width="120px"
          style="width: 100%; margin: 0 auto"
          label-position="left"
        >
          <el-form-item label="选择申报数量">
            <el-select v-model="numse" placeholder="请选择">
              <el-option :label="1" :value="1"></el-option>
              <el-option :label="2" :value="2"></el-option>
              <el-option :label="3" :value="3"></el-option>
              <el-option :label="4" :value="4"></el-option>
              <el-option :label="5" :value="5"></el-option>
              <el-option :label="6" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="wenzi">
          <div>突出贡献奖</div>
          <div>突出贡献奖将在其他奖项候选人汇总推荐产生</div>
        </div>
      </div>
      <div class="btn" v-if="isShow" @click="tijiaos">提交申报</div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible" width="30%" center>
      <span v-if="is_allow == 2"
        >您的申请未通过，原因为：{{ no_allow_resule }}。请重新提交</span
      >
      <span v-else>{{ no_allow_resule }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  base_upload,
  type,
  award_details,
  sub_data,
  is_allow,
  menu,
  user_num,
} from "../axios/api.js";
import axios from "axios";
export default {
  data() {
    return {
      img: "",
      numse: "",
      menuShow: true,
      img: "",
      danwei: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      options: [],
      options1: [],
      id: "",
      labelPosition: {},
      num: 1,
      head_Pic: require("../assets/static/img/mima.png"),
      dialogImageUrl: "",
      dialogVisible: false,
      centerDialogVisible: false,
      isShow: true,
      no_allow_resule: "",
      data: [],
      sum_count: "",
      sum_data: "",
      diff_data: "",
      is_allow: "",
      nums: "",
    };
  },
  async mounted() {
    let params = {
      token: sessionStorage.getItem("Token"),
    };
    let res = await is_allow(params);
    console.log(res);
    return;
    this.is_allow = res.data.data.is_allow;
    let is_allow1 = res.data.data.is_allow;
    let no_allow_resule = res.data.data.no_allow_resule;
    console.log(is_allow1);

    switch (is_allow1) {
      case "0":
        break;
      case 1:
        console.log("已通过");
        // alert("申请通过，请于6月1日后在线填写申报书")
        // this.centerDialogVisible = true;
        // this.no_allow_resule = "申请通过，请于6月7号后在线填写申报书";
        this.isShow = false;
        setTimeout(() => {
          let params = {
            token: sessionStorage.getItem("Token"),
          };
          let num = "";
          menu(params).then((res) => {
            console.log(res);
            console.log(res.data.data[0].data[0].menu_url);
            this.nums = res.data.data[0].data[0].menu_url;
            if (!this.nums) {
              this.$router.push({ path: "/" });
            } else {
              this.$router.push({ path: this.nums });
            }
          });
          console.log(this.nums);
        });
        break;
      case 2:
        this.centerDialogVisible = true;
        this.no_allow_resule = no_allow_resule;
        break;
      case 3:
        // alert("已提交，等待审核")
        this.centerDialogVisible = true;
        this.no_allow_resule = "已提交,后台审核中";
        this.isShow = false;
        this.isShow = false;
        break;
    }
  },
  created() {
    this.type();
  },
  components: {},

  methods: {
    tijiaos() {
      //
      let data = {
        num: this.numse,
        file_url: sessionStorage.getItem("img"),
        token: sessionStorage.getItem("Token"),
      };
      user_num(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "提交成功,等待管理员审核",
            type: "success",
          });
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    tijiao() {
      console.log(this.data);
      let y = true;
      let x = 0;
      let z = true;
      this.data.forEach((item) => {
        if (this.diff_data[0] == item.award_id) {
          if (item.num != 0) {
            this.data.forEach((item) => {
              if (this.diff_data[1] == item.award_id) {
                if (item.num != 0) {
                  this.$message.error(
                    "创新奖（单位）和 促进奖（单位）不能同时提交"
                  );
                  y = false;
                  return;
                }
              }
            });
          }
        }
        this.sum_data.forEach((item1) => {
          if (item1 == item.award_id) {
            let a = (x += parseInt(item.num));
            console.log(a);
            if (a > parseInt(this.sum_count)) {
              this.$message.error(
                `创新奖个人+促进奖个人+工匠精神奖合计人数不超过${this.sum_count}个`
              );
              z = false;
              return;
            }
          }
        });
      });

      if (y == false || z == false) {
      } else {
        let arr = [];
        this.options1.forEach((item) => {
          arr.push({
            award_id: item.award_id,
            num: 0,
            type_id: item.type_id,
          });
        });
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < this.data.length; j++) {
            if (arr[i].award_id == this.data[j].award_id) {
              arr[i].num = this.data[j].num;
            }
          }
        }
        let params = {
          img: sessionStorage.getItem("img"),
          type_id: this.id,
          data: arr,
          token: sessionStorage.getItem("Token"),
        };
        if (sessionStorage.getItem("img")) {
          console.log(params);
          sub_data(params).then((res) => {
            console.log(res);
            sessionStorage.removeItem("img");
            if (res.data.code == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
            } else {
              this.$message.error("上传失败");
            }
          });
        } else {
          return this.$message.error("上传失败,重新上传图片");
        }
      }
    },
    getDivision(e) {
      console.log(e);
      if (e.award_name == "突出贡献奖") {
        alert("此项将在其他奖项候选人汇总推荐产生");
      }
      let arr = [];
      this.data.forEach((item, index) => {
        console.log(item);
        if (item.award_id != e.award_id) {
          arr.push(item);
        }
      });
      arr.push({
        award_id: e.award_id,
        type_id: this.id,
        num: e.module[1],
      });

      this.data = arr;
      console.log(this.data);
    },

    changeOptions(val) {
      this.options.forEach((item) => {
        if (item.id == val) {
          this.diff_data = item.diff_data;
          this.sum_data = item.sum_data;
          this.sum_count = item.sum_count;
        }
      });
      console.log(val);
      this.id = val;
      let params = {
        type_id: val,
      };
      award_details(params).then((res) => {
        console.log(res);
        let options1 = res.data.data;
        console.log(options1);
        options1.forEach((item) => {
          item.cher = [];
          for (let i = 0; i < item.msx_num + 1; i++) {
            item.cher.push({ num: i });
          }
        });
        this.options1 = options1;
        console.log(options1);
      });
    },
    type() {
      type().then((res) => {
        // console.log(res);
        this.options = res.data.data;
      });
    },
    handleRemove(file) {
      console.log(file);
      this.$refs.upload.clearFiles(); //调用element官方的方法
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(123);
      this.getBase64(file.raw).then((res) => {});
    },

    getBase64(file) {
      let _that = this; //把图片转成base64编码
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
          let params = {
            img: imgResult,
          };
          console.log(params);
          base_upload(params).then((res) => {
            console.log(res);

            if (res.data.code == 200) {
              _that.$message({
                message: "图片上传成功",
                type: "success",
              });
              this.img = res.data.data;
              sessionStorage.setItem("img", this.img);
            } else {
              _that.$message({
                message: res.data.msg,
              });
            }
          });
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
  background: url("../assets/static/img/background.jpg") no-repeat 100%;
  background-size: cover;
  overflow: hidden;
  position: absolute;
}
.index_main {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  width: 700px;
  height: 900px;
  overflow-y: scroll;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px 60px;
}
.main_top {
  width: 100%;
  height: 150px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
}
h5 {
  margin: 10px;
}
.main_form {
  margin: 0 auto;
}
.btn {
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #890510;
  cursor: pointer;
  margin: 0 auto;
}
.upl_img {
  width: 100px;
  height: 100px;
}
.upl_img > img {
  width: 100%;
  height: 100%;
}
.el-icon-upload {
  font-size: 11px;
}
.main_top > div {
  display: flex;
  align-items: center;
}
.wenzi {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.wenzi > div:nth-child(1) {
  width: 120px;
  padding-right: 15px;
}
</style>